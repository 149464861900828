<template>
  <m-page-header title="数据统计" />
  <div class="view">
    <m-date-picker
      timeType="daterange"
      size="medium"
      @timeChange="timeChange"
      v-model="defaultValue"
    />
    <el-button type="primary" size="medium" @click="getCharts">查询</el-button>
  </div>
  <div class="line"></div>
  <div class="view">
    <div id="main"></div>
  </div>
</template>

<script>
import { charts } from '../api/DataAnalysis';
import { ref } from 'vue';
import * as echarts from 'echarts';
import { parseTime } from '@/tools/index';
export default {
  name: 'DataAnalysis',
  setup() {
    const option = ref({
      title: {
        text: '搜索图次数',
        subtext: '',
        subtextStyle: {
          fontSize: 18,
          fontWeight: 'bold',
          color: '#333',
        },
        top: 0,
        left: 20,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed', // y轴分割线类型
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLine: {
          show: true,
          lineStyle: { color: '#d0d0d0' },
        },
        axisLabel: {
          show: true,
          textStyle: { color: '#333', fontSize: 14 },
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: { color: '#d0d0d0' },
        },
        axisLabel: {
          show: true,
          textStyle: { color: '#333', fontSize: 14 },
        },
      },
      series: [
        {
          data: [],
          type: 'line',
        },
      ],
    });
    const condition = ref({
      start_day: '',
      end_day: '',
    });
    const defaultValue = ref([]);
    const getmonth = () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      defaultValue.value = [start, end];
      condition.value.start_day = parseTime(start, '{y}-{m}-{d}');
      condition.value.end_day = parseTime(end, '{y}-{m}-{d}');
      getCharts();
    };
    const getCharts = async () => {
      const { data, count } = await charts({
        start_day: condition.value.start_day,
        end_day: condition.value.end_day,
      });
      option.value.xAxis.data = data.x;
      option.value.series[0].data = data.y;
      option.value.title.subtext = count;
      /* eslint-disable */
      const myChart = echarts.init(document.getElementById('main'));
      /* eslint-disable */
      myChart.setOption(option.value);
    };
    const timeChange = val => {
      condition.value.start_day = val.startime;
      condition.value.end_day = val.endtime;
    };
    return {
      option,
      condition,
      defaultValue,
      timeChange,
      getCharts,
      getmonth,
    };
  },
  mounted() {
    this.getmonth();
  },
};
</script>

<style scoped lang="scss">
.view {
  padding: 15px;
  button {
    width: 106px;
    margin-left: 25px;
  }
  #main {
    width: 100%;
    height: 366px;
    overflow: hidden;
  }
}
.line {
  width: 100%;
  height: 10px;
  background-color: #f3f3f3;
}
</style>
